.panel {
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 222px auto 100px;
}

.panel-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type=email],
input[type=tel],
input[type=password] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-footer {
  text-align: center;
}
.form-footer input[type=submit] {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.form-footer input[type=submit]:hover {
  background-color: #45a049;
}
.form-footer p {
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}
.form-footer a {
  color: #4caf50;
  text-decoration: none;
}
.form-footer a:hover {
  text-decoration: underline;
}/*# sourceMappingURL=ugyfelkapu.css.map */