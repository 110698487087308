@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&family=EB+Garamond:wght@400;500&family=Ubuntu:wght@300;400&display=swap");
/*
font-family: 'Cardo', serif;
font-family: 'EB Garamond', serif;
font-family: 'Ubuntu', sans-serif;
$orange: rgb(255, 165, 0);
$navy: rgb(0, 0, 128);
*/
@import './kezdolap.css';
@import './szolgaltatasok.css';
@import './arak.css';
@import './magamrol.css';
@import './kapcsolat.css';
@import './galeria.css';
@import './szabalyzat.css';
@import './jo_tudni.css';
@import './akciok.css';
@import './gyik.css';
@import './felhasz_felt.css';
@import './admin.css';
@import './navigation.css';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
}

.App {
  overflow: hidden;
}
.App .status-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1100;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  border: 3px solid rgb(150, 150, 150);
  max-width: 500px;
}
.App #in-progress p {
  margin-right: 1rem;
}
.App #is-sent,
.App #discount,
.App #is-error {
  padding-top: 0;
  flex-direction: column;
}
.App #is-sent #close-icon,
.App #discount #close-icon,
.App #is-error #close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  position: relative;
  right: calc(-50% - 2rem);
  top: -1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  color: rgb(80, 80, 80);
  background-color: white;
  border: 3px solid rgb(150, 150, 150);
  border-radius: 50%;
}
.App #is-sent #close-icon:hover,
.App #discount #close-icon:hover,
.App #is-error #close-icon:hover {
  background-color: rgb(230, 0, 0);
  color: white;
}
.App #is-sent .icon,
.App #discount .icon,
.App #is-error .icon {
  font-size: 4rem;
  color: green;
  border: 4px solid green;
  border-radius: 50%;
  padding: 0.5rem;
}
.App #is-sent h2,
.App #is-sent h3,
.App #is-sent p,
.App #discount h2,
.App #discount h3,
.App #discount p,
.App #is-error h2,
.App #is-error h3,
.App #is-error p {
  margin-top: 1rem;
  text-align: center;
}
.App #is-sent h2,
.App #discount h2,
.App #is-error h2 {
  color: green;
  font-weight: 600;
}
.App #is-sent p,
.App #discount p,
.App #is-error p {
  text-align: center;
}
.App #is-error .icon {
  color: red !important;
  border-color: red;
}
.App #is-error h2,
.App #is-error h3,
.App #is-error p {
  color: red;
}
.App #discount a {
  margin-top: 1rem;
}

.up {
  position: fixed;
  bottom: 8rem;
  padding: 6px 3px;
  border-radius: 25% 0 0 25%;
  background-color: rgba(117, 189, 128, 0.7);
  transition: all 150ms ease-in-out;
  cursor: pointer;
  z-index: 10000;
}
.up .icon {
  font-size: 2rem;
  transform: rotate(-90deg);
  color: white;
}

.up:hover {
  background-color: rgb(117, 189, 128);
}

h1 {
  font-weight: 700;
  color: rgb(80, 80, 80);
  font-size: 2.5rem;
}

h2 {
  font-weight: 400;
  color: rgb(80, 80, 80);
}

h3 {
  font-weight: 400;
  color: rgb(80, 80, 80);
}

p {
  font-size: 1.15rem;
  color: rgb(100, 100, 100);
}

a {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.link-set .icon {
  transition: all 150ms ease-in-out;
}
.link-set .icon:hover {
  scale: 1.25;
  color: rgb(117, 189, 128);
}

button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  border: none;
  background-color: rgb(186, 222, 191);
  color: rgb(80, 80, 80);
  cursor: pointer;
  transition: all 150ms ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
button:hover {
  background-color: rgb(117, 189, 128);
  color: white;
}

header {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 1000;
}

footer {
  margin-top: unset;
  position: relative;
  z-index: 901;
}
footer .sec-nav {
  display: flex !important;
  justify-content: center;
  padding: 2rem 0;
}
footer .sec-nav .link-set {
  display: none;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page .head-section {
  display: flex;
  padding-top: 90px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.page .head-section .box {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.6));
  box-shadow: 30px 0 30px rgba(255, 255, 255, 0.6);
  margin-top: -90px;
  padding: calc(3rem + 90px) 3rem 50px 3rem;
}
.page .head-section .box h1 {
  margin-bottom: 2rem;
}
.page .head-section .box p {
  max-width: 400px;
  line-height: 1.3;
}
.page .section {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  scroll-margin-top: 100px;
}
.page .section .content-container {
  display: flex;
  place-content: center;
  width: 100%;
}
.page .section .content-container .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  padding: 2rem;
  margin: auto;
}
.page .section .content-container .box .slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page .section .content-container .box .slider .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}
.page .section .content-container .box .slider .button .icon {
  font-size: 2.5rem;
  border: 2px solid rgb(180, 180, 180);
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.page .section .content-container .box .slider .button .icon:hover {
  background-color: rgb(120, 120, 120);
  color: white;
}
.page .section .content-container .box .slider #previous-button .icon {
  transform: rotate(180deg);
}
.page .section .content-container .box .slider .wrapper p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
}
.page .section .content-container .box .slider .wrapper p i {
  margin: unset;
}
.page .section .content-container .box .slider .wrapper h3 {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .head-section {
    min-height: 400px !important;
  }
}
@media screen and (max-width: 768px) {
  .head-section {
    min-height: 300px !important;
  }
  .head-section .box {
    padding: calc(1rem + 64px) 1rem 50px 1rem !important;
  }
  .head-section .box h1 {
    font-size: 2rem;
    margin-bottom: 1rem !important;
  }
  .section .box {
    padding: 1rem !important;
    width: 100% !important;
  }
  .section .box .slider .button {
    display: none !important;
  }
}
@media screen and (max-width: 576px) {
  #is-sent,
  #discount,
  #is-error {
    width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
  }
  #is-sent p,
  #discount p,
  #is-error p {
    text-align: center;
  }
  #is-sent #close-icon,
  #discount #close-icon,
  #is-error #close-icon {
    right: -50% !important;
  }
  .head-section {
    min-height: 300px !important;
  }
}/*# sourceMappingURL=App.css.map */